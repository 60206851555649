import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { redirectTo } from '@reach/router'

import Loader from '../Loader'
import useCMS from '../Events/Wizard/useCMS'
import Zones from '../Events/Wizard/Zones'
import Categories from '../Events/Wizard/Categories'
import { Landing } from './Landing/Landing'


const {
  REACT_APP_HOME_ENDPOINT = 'https://brand.wiri.la/home'
} = process.env

const load = async country => {
  const path = ['home', country, 'html'].filter(Boolean).join('.')
  return fetch(`${REACT_APP_HOME_ENDPOINT}/${path}`).then(res => res.text()).catch(() => load())
}

const Html = () => {
  const cms = useCMS()
  const [categories, setCategories] = useState()
  const content = useQuery('home', () => load(cms?.country?.slug?.replace('/', '')), {
    refetchOnWindowFocus: false,
    enabled: !!cms?.country?.slug
  })

  useEffect(() => {
    const callback = ({ target }) => {
      try {
        const { tab } = target.dataset || {}

        if (target.closest('[data-toggle="categories"]')) {
          return setCategories(s => !s)
        }

        if (!tab) return

        const parent = target.parentElement
        if (parent.dataset.tabs === undefined) return

        const container = parent.nextElementSibling
        container.querySelectorAll('[data-tab]').forEach(element => {
          if (element.dataset.tab === tab) {
            element.classList.remove('hidden')
          } else {
            element.classList.add('hidden')
          }
        })

        parent.querySelectorAll('[data-tab]').forEach(element => {
          if (element.dataset.tab === tab) {
            element.classList.add('bg-indigo-100/70', 'text-primary')
            element.classList.remove('text-secondary/80')
          } else {
            element.classList.remove('bg-indigo-100/70', 'text-primary')
            element.classList.add('text-secondary/80')
          }
        })

      } catch (e) {
        console.error(e)
      }
    }
    document.addEventListener('click', callback)
    return () => document.removeEventListener('click', callback)
  }, [])


  if (!cms) return (
    <Loader className="mt-7 flex flex-col items-center" />
  )

  if (!cms.country?.slug) {
    window.location.href = "/ar"
    return null;
  }

  if (!cms.country?.slug) return (
    <Zones className="bg-white md:m-4 md:rounded-lg p-6 h-screen md:h-[100%!important]" />
  )

  if (!content.data) return (
    <Loader className="mt-7 flex flex-col items-center" />
  )

  return categories ? (
    <Categories className="bg-white md:m-4 md:rounded-lg p-6 h-screen md:h-[100%!important]" />
  ) : (
    <Landing />
  )
}

export default Html;
