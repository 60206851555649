const { REACT_APP_API_URL } = process.env

const request = async (path = '/', method, body) => {
  let verbose = body?.verbose;
  const options = {
    method: method || 'GET',
    headers: {'Content-Type': 'application/json'},
  }

  if (body?.constructor === Object || body?.constructor === Array) {
    if (body?.verbose) {
      delete body.verbose;
    }
    options.body = JSON.stringify(body)
    options.method = method || 'POST'
  }

  // console.log(`${REACT_APP_API_URL}${path}`, options)

  const res = await fetch(`${REACT_APP_API_URL}${path}`, options)

  if (!res.ok && verbose) {
    const errorData = await res.json().catch(() => ({}));
    throw { status: res.status, message: errorData?.status || "Request failed" };
  }
  
  if (!res.ok) return

  return res.json()
}

const api = (path, params) => request(path, null, params)

api.post = (path, body) => request(path, 'POST', body)
api.put = (path, body) => request(path, 'PUT', body)
api.get = (path, params) => request(path, 'GET', params)

export default api
