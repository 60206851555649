import { useTranslation } from 'react-i18next'

function License ({ license }) {

  const jurisdiction = license.jurisdiction?.split('/')?.reverse()?.[0]

  return (
    <div className="flex items-center gap-x-1" key={`${license.value}${license.jurisdiction || '-'}`}>
      <div className="w-[65px]">{license.value} </div>
      {!!jurisdiction && <div className="text-sm text-gray-400">{jurisdiction}</div>}
    </div>
  )
}

function Financier(props) {
  const { i18n } = useTranslation()
  return (
    <div>{i18n.financier(props.financier.id, props.financier.label)}</div>
  )
}

export function Bio({ resource }) {
  const { t } = useTranslation()

  if (!resource?.id) return null

  return (
    <div className="flex flex-col gap-y-8 bg-white p-4 mt-4 sm:rounded-lg" id="bio">
      {!!resource.description && (
        <div>
          <div className="text-lg font-medium mb-3">{t('bio', 'Bio')}</div>
          <div className="md:whitespace-pre-line">
            {resource.description}
          </div>
        </div>
      )}
      {resource.licenses?.length > 0 && (
        <div>
          <div className="text-lg font-medium mb-3">{t('license', 'Licencias', { count: resource.licenses.length })}</div>
          <div>
            {resource.licenses.map(l => (
              <License key={l.value} license={l} />
            ))}
          </div>
        </div>
      )}
      {resource.financiers?.length > 0 && (
        <div>
          <div className="text-lg font-medium mb-3">{t('work_with_financier_title', 'Coberturas que atiende', { count: resource.financiers.length })}</div>
          <div>
            {resource.financiers.sort((a, b) => {
              if (a.id < b.id) return -1
              if (a.id > b.id) return 1
              return 0
            }).map(f => (
              <Financier key={f.id} financier={f} />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
