import { useEffect, useState } from 'react'
import { useLocation, useNavigate, Link } from '@reach/router'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'

import api from '../services/api'
import Loader from '../Loader'
import { Header } from './Header/Header'
import { Services } from './Services/Services'
import { Availability } from './Availability/Availability'
import { Location } from './Location/Location'
import { Reviews } from './Reviews/Reviews'
import { Bio } from './Bio/Bio'

export function Resource(props) {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const params = new URLSearchParams(location.search)
  const [locations, setLocations] = useState([])
  const [services, setServices] = useState([])
  const [financiers, setFinanciers] = useState([])
  const [selected, setSelected] = useState()

  const flag = /wiri/.test(selected?.service?.id) ? true : 'all';

  let database
  let practitionerID
  try {
    const payload = JSON.parse(atob(props.id))
    database = payload.database
    practitionerID = payload.practitionerID
  } catch {}

  const redirect = !!(database || practitionerID)

  const resource = useQuery(
    ['resources', props.id],
    () => api.get(`/resources/${props.id}`),
    { enabled: !redirect }
  )

  const reviews = useQuery(
    ['resources', props.id, 'reviews'],
    () => api.get(`/resources/${props.id}/reviews`),
    { enabled: !redirect }
  )

  const options = {
    team: resource.data?.teamID,
    resource: `resources/${props.id}`,
    financier: selected?.financier?.id || 'private',
    location: selected?.location?.id,
    service: selected?.service?.id,
    visibility: 'unlisted',
    price: flag,
    marketplace: flag,
    valid: flag,
  }

  /* eslint-disable react-hooks/rules-of-hooks */

  const price = useQuery(
    ['availability/resources', options],
    () => api('/availability/resources', options).then(array => array?.[0]),
    { enabled: !!options.service && !!options.team && !redirect }
  );

  useEffect(() => {
    let locations = {}
    let services = {}
    let financiers = {}
    const items = resource.data?.services || []
    for (const item of items) {
      const [specialtyKey, practiceKey] = item.id.split('/')
      const [specialtyLabel, practiceLabel] = item.label.split('/').map(p => p?.trim())
      const { locationID, financierID } = item
      locations[locationID] ||= resource.data.locations.find(l => l.id === locationID)
      services[item.id] ||= { id: item.id, label: item.label }
      services[item.id].valid ||= item.valid
      financiers[financierID] ||= resource.data.financiers.find(f => f.id === financierID)

      financiers[financierID].services ||= {}
      financiers[financierID].locations ||= {}
      financiers[financierID].services[item.id] = true
      financiers[financierID].locations[locationID] = true

      services[item.id].financiers ||= {}
      services[item.id].locations ||= {}
      services[item.id].financiers[financierID] = true
      services[item.id].locations[locationID] = true

      locations[locationID].financiers ||= {}
      locations[locationID].services ||= {}
      locations[locationID].financiers[financierID] = true
      locations[locationID].services[item.id] = true
    }

    const params = new URLSearchParams(location.search)
    params.place = params.get('location') || params.get('lugar') || params.get('place')
    params.financier = params.get('financier') || params.get('cobertura') || 'private'
    params.specialty = params.get('specialty') || params.get('specialty')
    params.practice = params.get('practice') || params.get('practice')
    params.service = params.get('service') || params.get('servicio') || [params.specialty, params.practice].filter(Boolean).join('/')
    params.specialty ||= params.service?.split('/')?.[0]
    params.practice ||= params.service?.split('/')?.[1]
    const selected = {}

    selected.financier =
      financiers[params.financier] ||
      financiers[`${params.financier}/`] ||
      financiers[`pms_financiers:${params.financier}`] ||
      financiers[`pms_financiers:${params.financier}/`] ||
      financiers[`pms_financiers:private`]

    selected.service =
      services[`${params.specialty}/${params.practice}`] ||
      services[`pms_specialties:${params.specialty}/pms_practices:${params.practice}`] ||
      services[`pms_specialties:${params.specialty}/pms_practices:${params.practice}`]

    selected.location = locations[params.place]

    locations = Object.values(locations)
    services = Object.keys(services).sort().map(key =>
      ({ ...services[key] })
    )
    financiers = Object.keys(financiers).sort((a, b) => {
      if (/private/i.test(a)) return -1
      if (/private/i.test(b)) return 1
      return 0
    }).map(key =>
      ({ ...financiers[key] })
    )

    selected.location ||= locations[0]
    selected.service ||= services[0]
    selected.financier ||= financiers[0]

    setLocations(locations)
    setServices(services)
    setFinanciers(financiers)
    setSelected(selected)
  }, [location.pathname, location.search, resource.data])
  /* eslint-enable react-hooks/rules-of-hooks */

  if (redirect) {
    api.get(`/resources/migration/${database}/${practitionerID}`).then(({ link }) => {
      if (link) navigate(link, { replace: true })
    })
  }

  if (!resource.data?.id) return (
    <Loader className="flex items-center justify-center h-screen" />
  )

  return (
    <div>
      <Header
        resource={resource.data}
      />
      <Services
        price={price}
        locations={locations}
        services={services}
        financiers={financiers}
        selected={selected}
      />
      <Availability
        options={options}
      />
      <Location
        location={selected?.location}
      />
      <Reviews
        resource={resource.data}
        reviews={reviews.data}
      />
      <Bio
        resource={resource.data}
      />
    </div>
  )
}
